<template>
  <div>
    <div class="main-content card p-1" v-if="loader === 100">
      <div class="row">
        <div class="col-md-12">
          <label>Nome</label>
          <input type="text" class="form-control" v-model="video.titulo" disabled />
        </div>
        <div class="col-md-12">
          <label>Descrição</label>
          <textarea cols="30" rows="5" class="form-control" v-model="video.descricao" disabled></textarea>
        </div>
        <div class="col-md-12">
          <label>Arquivo</label>
          <input type="file" class="form-control" id="video" disabled>
        </div>
        <div class="col-md-12" v-if="video.id">
          <video :src="video.video_link" class="w-100 bg-dark" type="video/mp4" autoplay muted></video>
        </div>
        <div class="col-md-12 text-right">
          <hr />
          <router-link to="/videos" class="btn btn-back mr-1">
            <i class="fa fa-arrow-left"></i> Voltar
          </router-link>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            :style="'width: ' + loader + '%;'"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <b style="font-size: 14pt">Carregando</b><br />
        <b style="font-size: 16pt; line-height: 12pt">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      loader: 0,
      video: {},
    };
  },
  computed: {},
  methods: {
    getVideos: function (id) {
      const self = this;
      const api = self.$store.state.api + "videos/" + id;

      axios
        .get(api)
        .then((response) => {
          self.video = response.data.data[0];
          self.setLoader(50);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    setLoader: function (qtd) {
      const self = this;

      let load = 0;
      let interval = setInterval(function () {
        self.loader++;
        load++;

        if (load == qtd) {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.setLoader(50);

    if (id) {
      self.getVideos(id);
    } else {
      self.setLoader(50);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>