import Crud from '../views/Mensagem/Crud'
import Entity from '../views/Mensagem/Add'
import View from '../views/Mensagem/View'

let routes = {
    path: '/mensagens',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/mensagens/add',
            name: 'NewMensagem',
            component: Entity,
            meta: {
                pageTitle: 'Criação de Mensagens',
                breadcrumb: [
                    {
                        text: 'Mensagens',
                        active: false,
                        to: '/mensagens',
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/mensagens/edit/:id?',
            name: 'EditMensagem',
            component: Entity,
            meta: {
                pageTitle: 'Edição de Mensagens',
                breadcrumb: [
                    {
                        text: 'Mensagens',
                        active: false,
                        to: '/mensagens',
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/mensagens/view/:id?',
            name: 'ViewMensagem',
            component: View,
            meta: {
                pageTitle: 'Visualizar Mensagens',
                breadcrumb: [
                    {
                        text: 'Mensagens',
                        active: false,
                        to: '/mensagens',
                    },
                    {
                        text: 'Visualizar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/mensagens',
            name: 'list-mensagens',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Mensagens',
                breadcrumb: [
                    {
                        text: 'Mensagens',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
