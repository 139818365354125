import Crud from '../views/Painel/Crud'
import Entity from '../views/Painel/Add'
import View from '../views/Painel/View'

let routes = {
    path: '/paineis',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/paineis/add',
            name: 'NewPainel',
            component: Entity,
            meta: {
                pageTitle: 'Criação de Painel',
                breadcrumb: [
                    {
                        text: 'Paineis',
                        active: false,
                        to: '/paineis',
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/paineis/edit/:id?',
            name: 'EditPainel',
            component: Entity,
            meta: {
                pageTitle: 'Edição de Painel',
                breadcrumb: [
                    {
                        text: 'Paineis',
                        active: false,
                        to: '/paineis',
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/paineis/view/:id?',
            name: 'ViewPainel',
            component: View,
            meta: {
                pageTitle: 'Visualizar Painel',
                breadcrumb: [
                    {
                        text: 'Paineis',
                        active: false,
                        to: '/paineis',
                    },
                    {
                        text: 'Visualizar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/paineis',
            name: 'list-paineis',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Paineis',
                breadcrumb: [
                    {
                        text: 'Paineis',
                        active: true,
                    },
                ],
            },
        },
    ]
};

export default routes