<template>
  <div>
    <div class="main-content card p-1" v-if="loader === 100">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title">Adicionar Usuário</div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <form action>
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label for="Name">
                          <span class="required">*</span> Nome:
                        </label>
                        <input autocomplete="new-password" type="text" id="Name" class="form-control" v-model="users.name" placeholder="" disabled />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="Email">
                          <span class="required">*</span> Email:
                        </label>
                        <input autocomplete="new-password" class="form-control" id="Email" placeholder="" type="text" v-model="users.email" disabled />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="Login">
                          <span class="required">*</span> Login:
                        </label>
                        <input autocomplete="new-password" class="form-control" id="Login" placeholder="" type="text" v-model="users.login" disabled />
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-4">
                        <label for="Cellphone">Celular:</label>
                        <input autocomplete="new-password" class="form-control" id="Cellphone" type="text"
                          v-mask="'(99) 99999-9999'" v-model="users.cellphone" placeholder="" disabled />
                      </div>
                      <div class="form-group col-md-4">
                        <label>Tipo de Profissional:</label>
                        <v-select
                          :options="cbos"
                          :reduce="(cbos) => cbos.id"
                          :label="'nome'"
                          v-model="users.cbo_id"
                          class="vselect"
                          disabled
                        ></v-select>
                      </div>
                      <div class="form-group col-md-4">
                        <label for="inputUser">
                          <span class="required">*</span> Nível de acesso:
                        </label>
                        <select v-model="users.access_nivel" class="form-control" disabled>
                          <option :value="1" :key="1">Usuário Padrão</option>
                          <option :value="2" :key="2">Gestor Empresa</option>
                          <option v-if="$store.state.user.perfil_id == 6" :value="6" :key="6">
                            Administrador
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-12">
                        <label>Setor:</label>
                        <v-select
                          :options="setores"
                          :reduce="(setores) => setores.id"
                          :label="'nome'"
                          v-model="users.setores"
                          class="vselect"
                          multiple
                          disabled
                        ></v-select>
                      </div>
                    </div>
                    <div class="block text-right">
                      <router-link to="/users" class="btn btn-back">
                        <i class="fa fa-arrow-left"></i> Voltar
                      </router-link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div class="progress-bar bg-primary" role="progressbar" :style="'width: ' + loader + '%;'" aria-valuemin="0"
            aria-valuemax="100"></div>
        </div>
        <b style="font-size: 14pt;">Carregando</b><br>
        <b style="font-size: 16pt; line-height: 12pt;">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      loader: 0,
      companies: [],
      departments: [],
      change_password: false,
      cbos: [],
      users: {
        setores: [],
        name: "",
        email: "",
        cellphone: "",
        telphone: "",
        photo: "",
        access_nivel: "",
        password: "",
        password_confirmation: "",
        cbo_id: "",
      },
      companies: {},
      departments: {},
      errors: undefined,
      previewImage: null,
    };
  },
  computed: {},
  methods: {
    getUsers: function (id) {
      const self = this;
      const api = self.$store.state.api + `users/${id}?with[]=setores&paginated=false`;

      axios
        .get(api)
        .then((response) => {
          self.users = response.data.data[0];
          self.users.password = "";
          self.change_password = false;
          self.setLoader(35);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getCbos: function () {
      const self = this;
      const api = self.$store.state.api + "cbos?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.cbos = response.data.data
          self.setLoader(20);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSetores: function () {
      const self = this;
      const api = self.$store.state.api + "setores?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.setores = response.data.data
          self.setLoader(20);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (e) => {
        this.previewImage = e.target.result;
      };
    },
    setLoader: function (qtd) {
      const self = this;

      let load = 0;
      let interval = setInterval(function () {
        self.loader++;
        load++;

        if (load == qtd) {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.setLoader(25);
    self.getCbos();
    self.getSetores();


    if (id) {
      self.change_password = false;
      self.getUsers(id);
    } else {
      self.change_password = true;
      self.setLoader(35);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
  }
</style>