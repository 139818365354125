import Crud from '../views/Agendamento/Crud'
import Entity from '../views/Agendamento/Add'
import View from '../views/Agendamento/View'
import Calendar from '../views/Agendamento/Calendar'

let routes = {
    path: '/agendamentos',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/agendamentos/add',
            name: 'NewAgendamento',
            component: Entity,
            meta: {
                pageTitle: 'Criação de Agendamentos',
                breadcrumb: [
                    {
                        text: 'Agendamentos',
                        active: false,
                        to: '/agendamentos',
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/agendamentos/edit/:id?',
            name: 'EditAgendamento',
            component: Entity,
            meta: {
                pageTitle: 'Edição de Agendamentos',
                breadcrumb: [
                    {
                        text: 'Agendamentos',
                        active: false,
                        to: '/agendamentos',
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/agendamentos/view/:id?',
            name: 'ViewAgendamento',
            component: View,
            meta: {
                pageTitle: 'Visualizar Agendamento',
                breadcrumb: [
                    {
                        text: 'Agendamentos',
                        active: false,
                        to: '/agendamentos',
                    },
                    {
                        text: 'Visualizar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/agendamentos',
            name: 'list-agendamentos',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Agendamentos',
                breadcrumb: [
                    {
                        text: 'Agendamentos',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/agendamentos/calendar',
            name: 'calendar-agendamentos',
            component: Calendar,
            meta: {
                pageTitle: 'Agendamentos',
                breadcrumb: [
                    {
                        text: 'Agendamentos',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
