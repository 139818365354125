import Vue from 'vue'
import VueRouter from 'vue-router'

import Agendamento from './agendamentos'
import Botao from './botoes'
import Cbo from './cbo'
import Chamador from './chamadores'
import Classificacoes from './classificacoes'
import Finalizacao from './finalizacoes'
import Indicadores from './indicadores'
import Local from './locais'
import Manual from './manuais'
import Mensagem from './mensagens'
import MotivoCancelamento from './motivos'
import Painel from './paineis'
import Senhas from './senhas'
import Setor from './setores'
import Simbolo from './simbolos'
import Totem from './totens'
import Unidade from './unidades'
import User from './user'
import Video from './videos'
import Configuracao from './configuracoes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    Agendamento,
    Botao,
    Cbo,
    Chamador,
    Classificacoes,
    Configuracao,
    Finalizacao,
    Indicadores,
    Local,
    Manual,
    Mensagem,
    MotivoCancelamento,
    Painel,
    Senhas,
    Setor,
    Simbolo,
    Totem,
    Unidade,
    User,
    Video,
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
      },
    },
    {
      path: '/painel/:id?',
      name: 'painel',
      component: () => import('@/views/Painel.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/painel_atendimento/:id?',
      name: 'painel_atendimento',
      component: () => import('@/views/PainelAtendimento.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/painel_panico/:id?',
      name: 'painelPanico',
      component: () => import('@/views/PainelPanico.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/totem/:id?',
      name: 'totem',
      component: () => import('@/views/Totem.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const perfil_id = localStorage.getItem('perfil_id');
  const terms = localStorage.getItem('accept_terms');
  const authenticated = localStorage.getItem('token');
  let to_splited = to.path.toString().split('/')
  to_splited = to_splited.filter(part => part !== '')

  if (
    !authenticated &&
    (
      !(['/login'].includes(to.path.toString())) &&
      !(['public'].includes(to_splited[0])) &&
      !(['totem'].includes(to_splited[0])) &&
      !(['painel'].includes(to_splited[0])) &&
      !(['painel_atendimento'].includes(to_splited[0])) &&
      !(['painel_panico'].includes(to_splited[0]))
    )
  ) {
    next('/login');

    return true;
  }

  if (to.meta.perfil_id) {
    if (!to.meta.perfil_id.includes(perfil_id)) {
      next('/');
    }
  }

  next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
