import Crud from '../views/Video/Crud'
import Entity from '../views/Video/Add'
import View from '../views/Video/View'

let routes = {
    path: '/videos',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/videos/add',
            name: 'NewVideo',
            component: Entity,
            meta: {
                pageTitle: 'Criação de Video',
                breadcrumb: [
                    {
                        text: 'Videos',
                        active: false,
                        to: '/videos',
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/videos/edit/:id?',
            name: 'EditVideo',
            component: Entity,
            meta: {
                pageTitle: 'Edição de Video',
                breadcrumb: [
                    {
                        text: 'Videos',
                        active: false,
                        to: '/videos',
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/videos/view/:id?',
            name: 'ViewVideo',
            component: View,
            meta: {
                pageTitle: 'Visualizar Video',
                breadcrumb: [
                    {
                        text: 'Videos',
                        active: false,
                        to: '/videos',
                    },
                    {
                        text: 'Visualizar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/videos',
            name: 'list-videos',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Videos',
                breadcrumb: [
                    {
                        text: 'Videos',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
