import Crud from '../views/Manual/Crud'
import View from '../views/Manual/View'

let routes = {
    path: '/manuais',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/manuais/view/:id?',
            name: 'ViewManual',
            component: View,
            meta: {
                pageTitle: 'Visualizar Manual',
                breadcrumb: [
                    {
                        text: 'Manuais',
                        active: false,
                        to: '/manuais',
                    },
                    {
                        text: 'Visualizar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/manuais',
            name: 'list-manuais',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Manuais',
                breadcrumb: [
                    {
                        text: 'Manuais',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
