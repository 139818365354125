<template>
  <div>
    <div class="main-content card p-1" v-if="loader === 100">
      <div class="row">
        <div class="col-md-6">
          <label>Nome</label>
          <input type="text" class="form-control" v-model="local.nome" disabled/>
        </div>
        <div class="col-md-6">
          <label>Setor</label>
          <select v-model="local.setor_id" class="text-center form-control" disabled>
            <option v-for="setor in setores" :key="setor.id" :value="setor.id">
              {{ setor.nome }}
            </option>
          </select>
        </div>
        <div class="col-md-12 text-right">
          <hr />
          <router-link to="/locais" class="btn btn-back">
            <i class="fa fa-arrow-left"></i> Voltar
          </router-link>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            :style="'width: ' + loader + '%;'"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <b style="font-size: 14pt">Carregando</b><br />
        <b style="font-size: 16pt; line-height: 12pt">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      loader: 0,
      local: {},
      setores: {},
    };
  },
  computed: {},
  methods: {

    getLocais: function (id) {
      const self = this;
      const api = self.$store.state.api + "locais/" + id;

      axios
        .get(api)
        .then((response) => {
          self.local = response.data.data[0];
          self.setLoader(35);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSetores: function () {
      const self = this;
      const api = self.$store.state.api + "setores?ativo=1&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.setores = response.data.data;
          if (self.$route.params.id) {
            self.setLoader(30);
          } else {
            self.setLoader(50);
          }
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    setLoader: function (qtd) {
      const self = this;

      let load = 0;
      let interval = setInterval(function () {
        self.loader++;
        load++;

        if (load == qtd) {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.getSetores();

    if (id) {
      self.setLoader(35);

      self.getLocais(id);
    } else {
      self.setLoader(50);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>