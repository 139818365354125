import Crud from '../views/Setor/Crud'
import Entity from '../views/Setor/Add'
import View from '../views/Setor/View'

let routes = {
    path: '/setores',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/setores/add',
            name: 'NewSetor',
            component: Entity,
            meta: {
                pageTitle: 'Criação de Setor',
                breadcrumb: [
                    {
                        text: 'Setores',
                        active: false,
                        to: '/setores',
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/setores/edit/:id?',
            name: 'EditSetor',
            component: Entity,
            meta: {
                pageTitle: 'Edição de Setor',
                breadcrumb: [
                    {
                        text: 'Setores',
                        active: false,
                        to: '/setores',
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/setores/view/:id?',
            name: 'ViewSetor',
            component: View,
            meta: {
                pageTitle: 'Visualizar Setor',
                breadcrumb: [
                    {
                        text: 'Setores',
                        active: false,
                        to: '/setores',
                    },
                    {
                        text: 'Visualizar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/setores',
            name: 'list-setores',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Setores',
                breadcrumb: [
                    {
                        text: 'Setores',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
