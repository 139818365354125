<template>
  <div>
    <audio src="@/assets/audios/senhaNova.mp3"></audio>
    <div class="main-content card p-1" v-if="loader === 100">
      <div class="row" v-if="setor && local">
        <div class="col-md-6">
          <h4>
            Senhas no setor{{ setor_nome.length ? '(' +  setor_nome + ' - ' + local_nome  + ')' : "" }}: {{ senhas.length }} 
          </h4>
        </div>
        <div class="col-md-2">
          <button class="btn btn-sm btn-secondary m-0 w-100 d-flex justify-content-between align-items-center" @click="logoutChamador()" style="height: 100%;">
            <span class="mr-1">Trocar de setor/local</span>
            <i class="fas fa-sign-out-alt"></i>
          </button>
        </div>
        <div class="col-md-2">
          <button class="btn btn-sm btn-info m-0 w-100 d-flex justify-content-between align-items-center" @click="getAllSenhas()" style="height: 100%;">
            <span class="mr-1">Senhas Abertas</span>
            <i class="fas fa-list"></i>
          </button>
          <div class="modal fade" id="listaSenhas" tabindex="-1" role="dialog" aria-labelledby="title" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header bg-info">
                  <h5 class="modal-title text-light" id="title">Senhas Abertas ({{ allAtendimentos.length }})</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body text-center py-0">
                  <div class="row">
                    <div class="col-12" v-if="allAtendimentos.length">
                      <div class="row" v-for="entity in allAtendimentos">
                        <div class="col-6 p-1" style="border-right: 1px solid black; border-bottom: 1px solid #888;">
                          <h6>{{ entity.atendimento.prefixo + entity.atendimento.senha}}</h6>
                        </div>
                        <div class="col-6 p-1" style="border-bottom: 1px solid #888;">
                          {{ entity.setor.nome }}
                        </div>
                      </div>
                    </div>
                    <div class="text-center w-100" v-else>
                      <h5 class="b-1 w-100">Não há atendimentos abertos</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-2">
          <button class="btn btn-sm btn-primary m-0 w-100 d-flex justify-content-between align-items-center" @click="chamarProximo" :disabled="!senhas.length" style="height: 100%;">
            <span class="mr-1">Próxima Senha</span>
            <i class="fas fa-play"></i>
          </button>
        </div>
        <table class="table triped mt-1">
          <thead>
            <tr class="py-1">
              <th width="10%" class="align-top text-left">
                Senha
                <!-- <input type="text" class="form-control mt-1" v-model="senha" @input="setFilter" placeholder="Filtrar"> -->
              </th>

              <th width="30%" class="align-top text-left">
                Observação
              </th>
              <th width="10%" class="align-top text-center d-none d-md-table-cell">
                Grupo Prioritário
              </th>
              <th width="16%" class="align-top text-center d-none d-md-table-cell">
                Prioridade
              </th>
              <th width="16%" class="align-top text-center d-none d-md-table-cell">
                Status
              </th>
              <th width="10%" class="align-top text-center d-none d-lg-table-cell">
                Tempo na fila
              </th>
              <th width="8%" class="align-top text-left">
                Ações
              </th>
            </tr>
          </thead>
          <tbody v-if="senhas.length">
            <tr style="border-bottom: 1px solid #888;"  v-for="senha in senhas">
              <td><b>{{ senha.atendimento.prefixo }}{{ senha.atendimento.senha }} {{ senha.atendimento.atendimento_id_pai ? "(Subsenha)" : ""}}</b></td>
              <td>
                <span class="badge badge-danger" v-if="senha.user_id == $store.state.user.id">Você iniciou o atendimento dessa senha!</span>
                <span v-else>{{senha.atendimento.anotacao.length ? (senha.atendimento.anotacao[0].texto.substr(0, 120) + "...")  : 'Sem observações'}}</span>
              </td>
              <td class=" d-none d-md-table-cell text-center"><span class="badge" :style="'background:' + senha.classificacao.cor + ';'">{{ senha.classificacao.nome }}</span></td>
              <td class=" d-none d-md-table-cell text-center"><span :class="'badge badge-' + (senha.prioridade ? 'secondary' : 'danger')">{{ senha.prioridade_f }}</span></td>
              <td class=" d-none d-md-table-cell text-center">
                <span :class="'badge badge-' + (senha.status === 1 ? 'secondary' : (senha.status === 0 ? 'danger': 'info'))">{{ senha.status_text }}</span>
              </td>
              <td class="text-center d-none d-lg-table-cell">
                {{
                  senha.data_entrada_diff >= 3600 ?
                    Math.floor(senha.data_entrada_diff / 3600).toString().padStart(2, "0") + ":" +
                    Math.floor((senha.data_entrada_diff / 60) % 60).toString().padStart(2, "0") + ":" +
                    Math.floor(senha.data_entrada_diff % 60).toString().padStart(2, "0")
                    :
                    senha.data_entrada_diff >= 60 ?
                      "00:" +
                      Math.floor((senha.data_entrada_diff / 60) % 60).toString().padStart(2, "0") + ":" +
                      Math.floor(senha.data_entrada_diff % 60).toString().padStart(2, "0")
                     :
                      "00:00:" +
                      Math.floor(senha.data_entrada_diff % 60).toString().padStart(2, "0")
                }}
              </td>
              <td>
                <button
                  class="btn btn-sm btn-danger mr-1"
                  data-toggle="modal"
                  :data-target="'#cancelamento_' + senha.id"
                  :disabled="senha.status === 2 && senha.user_id != $store.state.user.id"  
                >
                  <i class="fas fa-times"></i>
                </button>
                <div class="modal fade" :id="'cancelamento_' + senha.id" tabindex="-1" role="dialog" aria-labelledby="title" aria-hidden="true">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header bg-danger">
                        <h5 class="modal-title text-light" id="title">Cancelar senha {{ senha.atendimento.prefixo}}{{ senha.atendimento.senha }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body text-center p-1">
                        Por favor, escolha o motivo do cancelamento
                        <v-select v-model="motivo_cancelamento" :options="motivos_cancelamento" :label="'titulo'"
                          :reduce="(motivos_cancelamento) => motivos_cancelamento.id" class="vselect"
                        />
                        Descreva-o
                        <textarea class="form-control" rows="5" v-model="cancelamento"></textarea>
                      </div>
                      <div class="modal-footer text-center">
                        <button class="btn btn-sm btn-secondary my-1" data-dismiss="modal" aria-label="Close" @click="cancelamento = ''">
                          Não Cancelar
                        </button>
                        <button class="btn btn-sm btn-danger my-1" @click="cancelar(senha.id)" data-dismiss="modal" aria-label="Close">
                          Cancelar Senha
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <button class="btn btn-sm btn-success" @click="atender(senha.id)" :disabled="senha.status === 2 && senha.user_id != $store.state.user.id"><i class="fas fa-play"></i></button>
                <button class="btn btn-sm btn-primary" @click="atenderSilencioso(senha.id)" :disabled="senha.status === 2 && senha.user_id != $store.state.user.id"><i class="fas fa-volume-mute"></i></button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
              <tr>
                <td class="table-bordered text-center" colspan="6">
                  <b>
                    Não existem senhas na fila
                  </b>
                </td>
              </tr>
          </tbody>
        </table>
      </div>
      <div class="row" v-else>
        <div class="col-12 mb-1">
          Selecione seu Setor
          <v-select v-model="setor" :options="setores" :label="'nome'"
            :reduce="(setores) => setores.id" class="vselect" @input="getLocais()"
          />
        </div>
        <div class="col-12 mb-1" v-if="setor">
          Selecione seu Local
          <v-select v-model="local" :options="locais" :label="'nome'"
            :reduce="(locais) => locais.id" class="vselect" @input="getSenhas(); getWebSocket(setor)"
          />
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div class="progress-bar bg-primary" role="progressbar" :style="'width: ' + loader + '%;'" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <b style="font-size: 14pt;">Carregando</b><br>
        <b style="font-size: 16pt; line-height: 12pt;">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
name: "imageUpload",
data() {
    return {
      loader: 0,
      setor: null,
      local: null,
      setor_nome: "",
      local_nome: "",
      cancelamento: "",
      motivo_cancelamento: "",
      intervalo: [],
      allAtendimentos: [],
      classificacao: [],
      motivos_cancelamento: [],
      setores: [],
      locais: [],
      senhas: {},
      contador: '',
      aviso: {
        length: 0,
        last: 0,
      },
      sugestao: {
        0: "Senha em duplicidade",
        1: "Senha aberta errada",
      },
      filtros: "",
      filtro: {
        status: "",
      }
    };
},
computed: {},
methods: {
  atender: function (id) {
    const self = this;
    let api = self.$store.state.api + "atender/" + id;
    self.$loading(true);
    clearInterval(self.contador);

    axios
      .post(api,  {local_id: self.local})
      .then(() => {
        self.$loading(false);

        this.$router.push("./atender/" + id);
      })
      .catch((error) => {
        self.$message(null, error.response.data, "error");
      });
  },
  atenderSilencioso: function(id){
    const self = this;
    let api = self.$store.state.api + "chamar_silencioso/" + id;

    self.$loading(true);
    clearInterval(self.contador);

    axios
      .post(api,  {local_id: self.local})
      .then(() => {
        self.$loading(false);

        this.$router.push("./atender/" + id);
      })
      .catch((error) => {
        self.$message(null, error.response.data, "error");
      });
  },
  cancelar: function(id){
    const self = this;
    let api = self.$store.state.api + "cancelar/" + id;
    self.$loading(true);

    axios
      .post(api, {motivo_cancelamento_id: self.motivo_cancelamento, cancelamento: self.cancelamento})
      .then(() => {
        self.cancelamento        = "";
        self.motivo_cancelamento = "";

        self.$loading(false);
      })
      .catch((error) => {
        self.$message(null, error.response.data, "error");
        self.$loading(false);
      });
  },
  getMotivoCancelamento: function(){
    const self = this;
    let api = self.$store.state.api + "motivo_cancelamentos/";

    self.$loading(true);

    axios
      .get(api)
      .then((response) => {
        self.motivos_cancelamento = response.data.data;
        self.$loading(false);
      })
      .catch((error) => {
        self.$message(null, error.response.data, "error");
        self.$loading(false);
      });
  },
  chamarProximo: function(){

    this.$loading(true);

    for(let i = 0 ; i < this.senhas.length ; i++)
    {
      if(this.senhas[i].status !== 2)
      {
        return this.atender(this.senhas[i].id);
      }
    }

    self.$loading(false);

    return this.$message(null, 'Não existem senhas para serem chamadas', "error");
  },
  getSenhas: function () {
    const self = this;
    const api = self.$store.state.api + "atendimento_setor?paginated=false&with[]=atendimento&whereNull=data_fim&with[]=classificacao&setor_id=" + self.setor;

    clearInterval(self.intervalo)

    axios
      .get(api)
      .then((response) => {
        if(self.aviso.length != response.data.data.length && self.aviso.last && self.aviso.last != response.data.data[response.data.data.length-1].id)
        {
          this.$message(null, "Nova senha no setor", "info");
          $('audio').trigger("play");
        }

        self.aviso.length = response.data.data.length;

        if(response.data.data.length)
        {
          self.aviso.last = response.data.data[response.data.data.length-1].id;
        }else{
          self.aviso.last = 0;
        }

        self.senhas = response.data.data.sort((a,b) => b.pontuacao - a.pontuacao);

        self.intervalo = setInterval(() => {
          self.senhas.forEach(senha => {
            senha.data_entrada_diff++;
          });
        }, 1000);

        self.$loading(false)
      })
      .catch((error) => {
        self.$loading(false)
        self.$message(null, error.response.data, "error");
      });
  },
  getClassificacao: function () {
    const self = this;
    const api = self.$store.state.api + "classificacoes?ativo=1&paginated=false";

    axios
      .get(api)
      .then((response) => {
        self.setLoader(25);

        self.classificacao = response.data.data;
      })
      .catch((error) => {
        self.$message(null, error.response.data, "error");
      });
  },
  getLocais: function () {
    const self = this;
    const api = self.$store.state.api + "locais-chamador/" + self.setor;

    axios
      .get(api)
      .then((response) => {
        self.locais = response.data;
      })
      .catch((error) => {
        self.$message(null, error.response.data, "error");
      });
  },
  getSetores: function () {
    const self = this;
    const api = self.$store.state.api + "setores-chamador/" + self.$store.state.user.id;

    axios
      .get(api)
      .then((response) => {
        let set = response.data;

        for(let i = 0 ; i < set.length ; i++)
        {
          if(set[i].possui_local)
          {
            self.setores.push(set[i]);
          }
        }

        self.setLoader(50);
      })
      .catch((error) => {
        self.$message(null, error.response.data, "error");
      });
  },
  getSetorNome: function () {
    const self = this;
    const api = self.$store.state.api + "setores?id=" + self.setor;

    axios
      .get(api)
      .then((response) => {
        self.setor_nome = response.data.data[0].nome;
      })
      .catch((error) => {
        self.$message(null, error.response.data, "error");
      });
  },
  getLocalNome: function () {
    const self = this;
    const api = self.$store.state.api + "locais?id=" + self.local;

    axios
      .get(api)
      .then((response) => {
        self.local_nome = response.data.data[0].nome;
      })
      .catch((error) => {
        self.$message(null, error.response.data, "error");
      });
  },
  setFiltros: function() {},
  sugestion: function (id) {
    this.cancelamento = this.sugestao[id];
  },
  getAllSenhas: function() {
    const self = this;
    const api = self.$store.state.api + "atendimento_setor?orderByAsc=atendimento_id&paginated=false&whereNull=data_fim&with[]=atendimento&with[]=setor";
    self.$loading(true);

    axios
    .get(api)
    .then((response) => {
      $('#listaSenhas').modal('toggle');
        self.$loading(false);

        self.allAtendimentos = response.data.data;
      })
      .catch((error) => {
        self.$message(null, error.response.data, "error");
      });
  },
  logoutChamador: function(){
    const self = this;
    const api = self.$store.state.api + "logout_chamador/" + self.$store.state.user.id;

    self.$loading(true);
    self.$store.state.user.setor = null;
    self.$store.state.user.local = null;
    self.setor = null;
    self.local = null;
    self.loader = 0;
    self.setores = [];
    self.senhas = [];

    for(let i = 0 ; i < 99999 ; i++)
    {
      clearInterval(i);
    }

    axios
      .post(api)
      .then((response) => {
        
        self.setLoader(50);
        self.$loading(false);
      })
      .catch((error) => {
        self.setLoader(50);
        self.$message(null, error.response.data, "error");
      });

    
    self.getSetores();
  },
  getWebSocket(id) {
    const self = this;

    self.getSetorNome();
    self.getLocalNome();
    self.$store.commit("setChamador", {setor: self.setor, local: self.local});

    clearInterval(self.intervalo);

    setTimeout(function () {
      Echo.private(`chamador.${id}`).listen(".ChamadorEvent", (data) => {
        self.senhas = data[0];
      })
    }, 3000);
  },
  setLoader: function(qtd) {
    const self = this;

    let load = 0; 
    let interval = setInterval(function(){      
      self.loader++;
      load++;

      if(load == qtd)
      {
        clearInterval(interval);
      }
    }, 15);
  },
},
mounted: function () {
  const self = this;
  self.setLoader(25);
  self.getClassificacao();
  self.getMotivoCancelamento();

  setTimeout(function(){
    if(self.$store.state.user.setor)
    {
      self.setor = self.$store.state.user.setor;
      self.getSetorNome();

      self.setLoader(50);
    }else{
      self.getSetores();
    }

    if(self.$store.state.user.local)
    {
      self.local = self.$store.state.user.local;

      self.getLocalNome();
      self.getSenhas();
      self.getWebSocket(self.$store.state.user.setor);
    }
  }, 1000);
},
beforeDestroy() {
  const self = this;

  self.$loading(true);

  for(let i = 0 ; i < 100000 ; i++)
  {
    clearInterval(i);
  }

  console.log('teste');

  self.$loading(false);
},
components: {
    BaseCrud,
    vSelect,
},
};
</script>
<style scoped>
.profile_user {
text-align: center;
}
.photo {
display: block;
margin-left: 5px;
width: 18%;
border-radius: 10px;
max-width: 400px;
max-height: 400px;
object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
background: linear-gradient(to right, #000, #666);
color: #fff !important ;
}
.photo-title {
color: #000;
font-size: 18px;
display: block;
margin-left: 5px;
width: 18%;
}
</style>