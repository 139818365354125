<template>
  <div>
    <div class="main-content card" v-if="loader === 100">
      <div class="row p-1">
        <div class="col-md-1">
          <br>
          <button class="btn w-100 px-0 btn-outline-primary" @click="setSemana()">Semanal</button>
        </div>
        <div class="col-md-1">
          <br>
          <button class="btn w-100 px-0 btn-outline-primary" @click="setMes()">Mensal</button>
        </div>
        <div class="col-md-1">
          <br>
          <button class="btn w-100 px-0 btn-outline-primary" @click="setAno()">Anual</button>
        </div>
        <div class="col-md-3">
          <label>Data inicial</label>
          <input type="date" class="form-control" v-model="pesquisa.data_inicio">
        </div>
        <div class="col-md-3">
          <label>Data final</label>
          <input type="date" class="form-control" v-model="pesquisa.data_fim">
        </div>
        <div class="col-md-1">
          <br>
          <button class="btn btn-primary" @click="getEstatisticas()">
            <i class="fas fa-search"></i>
          </button>
        </div>
        <div class="col-md-1">
          <br>
          <button class="btn btn-danger" :disabled="(estatisticas.length ? false : true)" @click="getPdf()"><i class="fas fa-file-pdf"></i></button>
        </div>
        <div class="col-md-1">
          <br>
          <button class="btn btn-success" :disabled="(estatisticas.length ? false : true)" @click="getExcel()"><i class="fas fa-file-excel"></i></button>
        </div>
        <div class="col-12 p-1" style="overflow-x: auto" v-if="estatisticas.length">
          <hr>
          <table class="table table-borderd text-center" style="overflow-x: auto">
            <thead>
              <th>Indicador</th>
              <th v-for="estatistica in estatisticas">{{ estatistica.date }}</th>
            </thead>
            <tr>
              <td>Total de senhas Emitidas</td>
              <td v-for="estatistica in estatisticas">{{ estatistica.senhas.total }}</td>
            </tr>
            <tr>
              <td>Senhas comuns</td>
              <td v-for="estatistica in estatisticas">{{ estatistica.senhas.comum }}</td>
            </tr>
            <tr>
              <td>Senhas preferenciais</td>
              <td v-for="estatistica in estatisticas">{{ estatistica.senhas.preferencial }}</td>
            </tr>
            <tr>
              <td>Senhas prioritárias</td>
              <td v-for="estatistica in estatisticas">{{ estatistica.senhas.prioritario }}</td>
            </tr>
            <tr>
              <td colspan="999"></td>
            </tr>
            <tr>
              <td>Senhas ausentes</td>
              <td v-for="estatistica in estatisticas">{{ estatistica.finalizacoes.ausente }}</td>
            </tr>
            <tr>
              <td>Senhas evadidas</td>
              <td v-for="estatistica in estatisticas">{{ estatistica.finalizacoes.evadida }}</td>
            </tr>
            <tr>
              <td>Senhas canceladas</td>
              <td v-for="estatistica in estatisticas">{{ estatistica.finalizacoes.cancelada }}</td>
            </tr>
            <tr>
              <td>Senhas pendentes</td>
              <td v-for="estatistica in estatisticas">{{ estatistica.finalizacoes.pendentes }}</td>
            </tr>
            <tr v-for="finalizacao, index in finalizacoes">
              <td>Finalização: {{ finalizacao.nome }}</td>
              <td v-for="estatistica in estatisticas">
                <span>
                  {{ estatistica.finalizacoes.finalizacao.qtd[index]}}
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="999"></td>
            </tr>
            <tr v-for="setor, index in setores">
              <td>Tempo médio de espera: {{ setor.nome }}</td>
              <td v-for="estatistica in estatisticas">
                <span>
                  {{ estatistica.setores.tempo[index]}}
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="999"></td>
            </tr>
            <tr v-for="botao, index in botoes">
              <td>Demanda: {{ botao.nome }}</td>
              <td v-for="estatistica in estatisticas">
                <span>
                  {{ estatistica.botoes.qtd[index]}}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div class="progress-bar bg-primary" role="progressbar" :style="'width: ' + loader + '%;'" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <b style="font-size: 14pt;">Carregando</b><br>
        <b style="font-size: 16pt; line-height: 12pt;">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>

import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      configuracao: [],
      loader: 0,
      estatisticas: [],
      botoes: [],
      finalizacoes: [],
      setores: [],
      pesquisa: {
        demanda: "",
        data_inicio: "",
        data_fim: "",
      },
    };
  },
  computed: {},
  methods: {
    getEstatisticas: function () {
      const self = this;
      const api = self.$store.state.api + "estatisticas/";

      self.estatisticas = [];
      console.clear();

      if(self.pesquisa.data_inicio != "" && self.pesquisa.data_fim != "")
      {
        self.$loading(true);
        
        axios
          .post(api, self.pesquisa)
          .then((response) => {
            self.estatisticas = response.data.data;
            self.botoes       = response.data.botoes;
            self.finalizacoes = response.data.finalizacoes;
            self.setores      = response.data.setores;
            self.$loading(false);
          })
          .catch((error) => {
            self.$loading(false);
            console.log(error)
            self.$message(null, error, "error");
          });
      }else{
        self.$message(null, "Por favor, a data inicial e a data final", "error");
      }
    },
    getPdf: function()
    {
      const self = this;
      const api = self.$store.state.api + "pdf_estatisticas/";

      self.$loading(true);

      axios
        .post(api, self.pesquisa)
        .then((response) => {
          self.openPdf(response.data);
          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    openPdf: function(hash){
      const self = this;

      window.open(`${self.$store.state.url}pdf_estatisticas?h=${hash}&c=${self.estatisticas.length}`, "_blank");
    },
    getExcel: function()
    {
      const self = this;
      const api = self.$store.state.api + "excel_estatisticas/";

      axios
        .post(api, self.pesquisa)
        .then((response) => {
          self.openExcel(response.data);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    openExcel: function(data){
      const self = this;

      window.open(`${self.$store.state.url}excel_estatisticas?k=${data}`, "_blank");
    },
    getConfiguracao: function () {
      const self = this;
      const api = self.$store.state.api + "configuracoes/";

      axios
        .get(api)
        .then((response) => {
          self.configuracao = response.data.data[0];
          self.setLoader(30);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getBotoes: function () {
      const self = this;
      const api = self.$store.state.api + "botoes?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.botoes = response.data.data;
          self.setLoader(30);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    setSemana: function(){
      const self = this;
      const now = new Date();

      self.pesquisa.data_inicio = self.formatDate(new Date(now.setDate(now.getDate() - now.getDay())));
      self.pesquisa.data_fim    = self.formatDate(new Date(now.setDate(now.getDate() + (6 - now.getDay()))));
    },
    setMes: function(){
      const self = this;
      const now = new Date();

      self.pesquisa.data_inicio = self.formatDate(new Date(now.getFullYear(), now.getMonth(), 1));
      self.pesquisa.data_fim    = self.formatDate(new Date(new Date(now.getFullYear(), now.getMonth() + 1, 1) - 1));
    },
    setAno: function(){
      const self = this;
      const now = new Date();

      self.pesquisa.data_inicio = self.formatDate(new Date(now.getFullYear(), 0, 1));
      self.pesquisa.data_fim    =  self.formatDate(new Date(now.getFullYear(), 11, 31));
    },
    formatDate: function(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    setLoader: function(qtd) {
      const self = this;

      let load = 0; 
      let interval = setInterval(function(){      
        self.loader++;
        load++;

        if(load == qtd)
        {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.setLoader(40);
    self.getBotoes();
    self.getConfiguracao();
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>