<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <base-crud
          :ref="'agendamentosCrud'"
          crudName="Agendamentos"
          newText="Novo Agendamento"
          :table="table"
          :columns="columns"
          :options="options"
          :endPoint="endPoint"
          :enableDownloadPdf="false"
          :enableCalendar="true"
          :enableAdd="true"
          :enableEdit="true"
          :enableDelete="true"
          :enableView="true"
          :enableActive="false"
          :enableReset="false"
          :enableSenhaPath="false"
          :dataForm="dataForm"
        >
        </base-crud>
      </div>
    </div>
  </div>
</template>

<script>
import BaseCrud from "../../layouts/Base/BaseCrud";

export default {
  data: function () {
    return {
      table: "agendamentosTable",
      showMessageImport: false,
      usersCrud: {},
      pages: [],
      url: "",
      columns: ["id", "nome", "cpf", "data_agendado_f", "status", "actions"],
      tableData: ["id", "nome", "cpf", "data_agendado_f", "status"],
      options: {
        filterByColumn: true,
        debounce: 1000,
        filterable: ["nome", "cpf", "data_agendado_f"],
        pagination: { chunk: 10, dropdown: false, nav: "scroll" },
        perPage: 10,
        perPageValues: [10, 25, 50, 100],
        headings: {
          id: "ID",
          nome: "Nome",
          data_agendado_f: "Data Agendada",
          actions: "Ações",
        },
        texts: {
          filterBy: "Filtrar",
          defaultOption: "Selecione",
        },
        listColumns: {},
        templates: {
          status: function (h, row, index) {
            switch(row.status)
            {
              case(0):
                return (
                  <h6 class="text-center">
                    <span class="badge badge-danger">Agendamento Cancelado</span>
                  </h6>
                );
              case(1):
                return (
                  <h6 class="text-center">
                    <span class="badge badge-primary">Agendado</span>
                  </h6>
                );
              case(2):
                return (
                  <h6 class="text-center">
                    <span class="badge badge-danger">Ausente</span>
                  </h6>
                );
              case(3):
                return (
                  <h6 class="text-center">
                    <span class="badge badge-success">Presente</span>
                  </h6>
                );
              case(4):
                return (
                  <h6 class="text-center">
                    <span class="badge badge-secondary">Atendido</span>
                  </h6>
                );
            }
          },
        },
        requestFunction: function (data) {
          let requestData = {};

          let query = this.$parent.$parent.$parent.query(data.query);
          requestData.params = data;
          requestData.params.query = "";
          requestData.params.with = [];

          return this.$http.get(this.url + "?" + query, requestData).catch(
            function (e) {
              this.dispatch("error", e);
            }.bind(this)
          );
        },
      },
      endPoint: "agendamentos/",
      dataForm: {},
    };
  },
  components: {
    BaseCrud,
  },
  methods: {
    query: function (query) {
      let columns = {
        id: "id",
        nome: "nome",
        data_agendado_f: "data_agendado",
      };
      let filters = "";
      $.each(query, function (index, value) {
        filters += columns[index] + "=" + value + "&";
      });
      return filters;
    },
  },
};
</script>

<style scoped>
.VuePagination {
  text-align: center;
}
.uploading-image {
  display: flex;
  border-radius: 10px;
  width: 100px;
  height: 100px;
}
</style>