<template>
  <div>
    <div class="main-content card p-1" v-if="loader === 100">
      <div class="row">
        <div class="col-md-12">
          <label>Nome</label>
          <input type="text" class="form-control" v-model="setor.nome" disabled />
        </div>
        <div class="col-md-3">
          <br />
          <label
            class="btn btn-primary w-100"
            v-bind:class="{ disabled: !setor.classifica }"
            style="cursor: pointer"
          >
            <input type="checkbox" v-model="setor.classifica" class="d-none" disabled />
            Classifica Risco
          </label>
        </div>
        <div class="col-md-3">
          <br />
          <label
            class="btn btn-primary w-100"
            v-bind:class="{ disabled: !setor.finaliza }"
            style="cursor: pointer"
          >
            <input type="checkbox" v-model="setor.finaliza" class="d-none" disabled />
            Finaliza Senha
          </label>
        </div>
        <div class="col-md-3">
          <br />
          <label
            class="btn btn-primary w-100"
            v-bind:class="{ disabled: !setor.medicacao }"
            style="cursor: pointer"
          >
            <input type="checkbox" v-model="setor.medicacao" class="d-none" disabled />
            Envia para Medicação
          </label>
        </div>
        <div class="col-md-3">
          <br />
          <label
            class="btn btn-primary w-100"
            v-bind:class="{ disabled: !setor.espera }"
            style="cursor: pointer"
          >
            <input type="checkbox" v-model="setor.espera" class="d-none" disabled />
            Envia para Espera
          </label>
        </div>
        <div class="col-md-3">
          <br />
          <label
            class="btn btn-primary w-100"
            v-bind:class="{ disabled: !setor.prioridade }"
            style="cursor: pointer"
          >
            <input
              type="checkbox"
              v-model="setor.prioridade"
              class="d-none"
              disabled
            />
            Muda prioridade
          </label>
        </div>
        <div class="col-md-3">
          <br />
          <label
            class="btn btn-primary w-100"
            v-bind:class="{ disabled: !setor.envia_ares_triagem }"
            style="cursor: pointer"
          >
            <input
              type="checkbox"
              v-model="setor.envia_ares_triagem"
              class="d-none"
              disabled
            />
            Envia para Ares Triagem
          </label>
        </div>
        <div class="col-md-12 mt-2">
          <h4>Fluxo</h4>
        </div>
        <div class="col-md-3" v-for="fluxo in allSetores" :key="fluxo.id">
          <label
            class="btn btn-primary w-100"
            v-bind:class="{ disabled: !fluxo.ativo }"
            style="cursor: pointer"
          >
            <input type="checkbox" v-model="fluxo.ativo" class="d-none" disabled />
            {{ fluxo.nome }}
          </label>
        </div>

        <div class="col-md-12 text-right">
          <hr />
          <router-link to="/setores" class="btn btn-back">
            <i class="fa fa-arrow-left"></i> Voltar
          </router-link>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            :style="'width: ' + loader + '%;'"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <b style="font-size: 14pt">Carregando</b><br />
        <b style="font-size: 16pt; line-height: 12pt">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      loader: 0,
      setor: {},
      allSetores: [],
    };
  },
  computed: {},
  methods: {
    getSetores: function (id) {
      const self = this;
      const api = self.$store.state.api + "setores/" + id + "?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.setor = response.data.data[0];
          self.setLoader(25);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAllSetores: function () {
      const self = this;
      var api = self.$store.state.api + "setores?ativo=1&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.allSetores = response.data.data;

          self.setLoader(25);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getFluxos: function (id) {
      const self = this;
      var api = self.$store.state.api + `fluxo_setores?with[]=setor&paginated=false&setor_original_id=${id}`;

      axios
        .get(api)
        .then((response) => {
          if (response.data && response.data.data) {
            response.data.data.forEach((setor) => {
              setor.nome = setor.setor ? setor.setor.nome : "";
            });

            self.allSetores = response.data.data;
            self.setLoader(25);
          } else {
            console.error("Unexpected response format:", response);
            self.$message(null, "Unexpected response format", "error");
          }
      });
    },
    setLoader: function (qtd) {
      const self = this;

      let load = 0;
      let interval = setInterval(function () {
        self.loader++;
        load++;

        if (load == qtd) {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.setLoader(50);

    if (id) {
      self.getSetores(id);
      self.getFluxos(id);
    } else {
      self.getAllSetores();
      self.setLoader(25);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>