<template>
  <div>
    <div class="main-content card p-1" v-if="loader === 100">
      <div class="row">
        <div class="col-md-12">
          <label>Nova senha</label>
          <input type="text" class="form-control" v-model="passwords.new_password" />
          <label>Confirme a nova senha</label>
          <input type="text" class="form-control" v-model="passwords.confirm_password" />
        </div>
        <div class="col-md-12 text-right">
          <hr />
          <router-link to="/users" class="btn btn-back mr-1">
            <i class="fa fa-arrow-left"></i> Voltar
          </router-link>
          <a class="btn btn-add" @click="save">
            Salvar
            <i class="fa fa-save"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            :style="'width: ' + loader + '%;'"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <b style="font-size: 14pt">Carregando</b><br />
        <b style="font-size: 16pt; line-height: 12pt">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      loader: 0,
      passwords: {
        new_password: "",
        confirm_password: "",
      },
      user: {},
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      let api = self.$store.state.api + "reset-password/" + self.$route.params.id;

      self.$loading(true);

      axios
        .post(api, self.passwords)
        .then((response) => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          self.$loading(false);

          this.$router.push("/users");
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    setLoader: function (qtd) {
      const self = this;

      let load = 0;
      let interval = setInterval(function () {
        self.loader++;
        load++;

        if (load == qtd) {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;
    self.$route.params.id;

    self.setLoader(100);
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
  }
</style>