<template>
  <div>
    <div class="main-content card" v-if="loader === 100">
      <div class="row p-1">
        <div class="col-md-1">
          <label>Prefixo</label>
          <input type="text" class="form-control" v-model="pesquisa.prefixo">
        </div>
        <div class="col-md-2">
          <label>Senha</label>
          <input type="text" class="form-control" v-model="pesquisa.senha">
        </div>
        <div class="col-md-3">
          <label>Data inicial</label>
          <input type="date" class="form-control" v-model="pesquisa.data_inicio">
        </div>
        <div class="col-md-3">
          <label>Data final</label>
          <input type="date" class="form-control" v-model="pesquisa.data_fim">
        </div>
        <div class="col-md-1">
          <br>
          <button class="btn btn-primary" @click="getSenhas()">
            <i class="fas fa-search"></i>
          </button>
        </div>
        <div class="col-md-1">
          <br>
          <button class="btn btn-danger" :disabled="(senhas.length ? false : true)" @click="getPdf()"><i class="fas fa-file-pdf"></i></button>
        </div>
        <div class="col-md-1">
          <br>
          <button class="btn btn-success" :disabled="(senhas.length ? false : true)" @click="getExcel()"><i class="fas fa-file-excel"></i></button>
        </div>
        <div class="col-12 p-1" style="overflow-x: scroll;">
          <hr>
          <table class="table table-borderd">
            <thead>
              <th align="center">Senha</th>
              <th align="center">Pessoa</th>
              <th align="center">Demanda</th>
              <th align="center">Setor</th>
              <th align="center">Local</th>
              <th align="center">Status</th>
              <th align="center">Data de entrada</th>
              <th align="center">Tempo de espera</th>
              <th align="center">Última chamada</th>
              <th align="center">Data final</th>
              <th align="center">Tempo total</th>
            </thead>
            <tbody>
              <tr v-for="senha in senhas">
                <td align="center">
                  <b>{{ senha.atendimento.prefixo }}{{ senha.atendimento.senha }}</b>
                </td>
                <td align="center">
                  {{ senha.atendimento.pessoa ? senha.atendimento.pessoa.nome : "Não informado"}}
                </td>
                <td align="center">
                  {{ senha.atendimento.botao ? senha.atendimento.botao.nome : "Não disponível"}}
                </td>
                <td align="center">
                  {{ senha.setor ? senha.setor.nome : "Não disponível"}}
                </td>
                <td align="center">
                  {{ senha.local ? senha.local.nome : "Não atendido"}}
                </td>
                <td align="center">
                  <span :class="'badge badge-' + (senha.status === 1 ? 'secondary' : (senha.status === 0 ? 'danger': 'info'))">{{ senha.status_text }}</span>
                </td>
                <td align="center">
                  {{ senha.data_entrada_f }}
                </td>
                <td align="center">
                  {{ senha.tempo_espera }}
                </td>
                <td align="center">
                  {{ senha.data_ultima_chamada ? senha.data_ultima_chamada_f : "Não chamado" }}
                </td>
                <td align="center">
                  {{ senha.data_fim_f ? senha.data_fim_f : "Não finalizado" }}
                </td>
                <td align="center">
                  {{ senha.tempo_total }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div class="progress-bar bg-primary" role="progressbar" :style="'width: ' + loader + '%;'" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <b style="font-size: 14pt;">Carregando</b><br>
        <b style="font-size: 16pt; line-height: 12pt;">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>

import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      configuracao: [],
      loader: 0,
      senhas: [],
      pesquisa: {
        prefixo: "",
        senha: "",
        data_inicio: "",
        data_fim: "",
      },
    };
  },
  computed: {},
  methods: {
    getSenhas: function () {
      const self = this;
      const api = self.$store.state.api + "acompanhamento/";

      self.senhas = [];

      if(self.pesquisa.data_inicio != "" || self.pesquisa.data_fim != "")
      {
        self.$loading(true);
        
        axios
          .post(api, self.pesquisa)
          .then((response) => {
            self.senhas = response.data;

            self.$loading(false);
          })
          .catch((error) => {
            self.$loading(false);
            self.$message(null, error.response.data, "error");
          });
      }else{
        self.$message(null, "Por favor, insira ao menos data inicial ou final", "error");
      }
    },
    getPdf: function()
    {
      const self = this;
      const api = self.$store.state.api + "pdf_acompanha/";

      axios
        .post(api, self.senhas)
        .then((response) => {
          self.openPdf(response.data);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    openPdf: function(hash){
      const self = this;

      window.open(`${self.$store.state.url}pdf_acompanhamento?h=${hash}&c=${self.senhas.length}`, "_blank");
    },
    getExcel: function()
    {
      const self = this;
      const api = self.$store.state.api + "excel_acompanha/";

      axios
        .post(api, self.senhas)
        .then((response) => {
          self.openExcel(response.data);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    openExcel: function(ids){
      const self = this;

      window.open(`${self.$store.state.url}excel_acompanhamento?id=${ids}`, "_blank");
    },
    getClock(tempo_inicial){
      const self = this;

      var segundos = tempo_inicial;
      var minutos = 0;
      var horas = 0;

      if (segundos > 60) {
        minutos = Math.floor(segundos / 60);
      }

      if (minutos > 60) {
        horas = Math.floor(minutos / 60);
      }

      return horas.toString().padStart(2, "0") + ":" + (minutos % 60).toString().padStart(2, "0") + ":" + (segundos % 60).toString().padStart(2, "0");
    },
    getConfiguracao: function () {
      const self = this;
      const api = self.$store.state.api + "configuracoes/";

      axios
        .get(api)
        .then((response) => {
          self.configuracao = response.data.data[0];
          self.setLoader(50);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    setLoader: function(qtd) {
      const self = this;

      let load = 0; 
      let interval = setInterval(function(){      
        self.loader++;
        load++;

        if(load == qtd)
        {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.setLoader(50);
    self.getConfiguracao();
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>