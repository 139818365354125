<template>
  <div>
    <div class="main-content card p-1" v-if="loader === 100">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-end mb-1">
          <button class="btn btn-primary" data-toggle="modal" data-target="#carregar">
            <i class="fas fa-redo"></i>
            Carregar calendario completo
          </button>
          <div class="modal fade" id="carregar" tabindex="-1" role="dialog" aria-labelledby="carregar" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header bg-warning">
                  <h5 class="modal-title" style="color: #ffffff !important">
                    Deseja carregar o calendário completo?
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body my-1">
                  <b>Atenção</b>, ao carregar o calendário inteiro, você recebera todos os agendamentos já feitos, o que pode gerar uma lentidão na página, tem certeza disso?
                </div>
                <div class="modal-footer text-right">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Fechar
                  </button>
                  <button class="btn btn-primary ml-1" data-dismiss="modal" @click="getAgendaCompleta()">Carregar</button>
                </div>
              </div>
            </div>
          </div>
          <router-link to="/agendamentos" class="btn btn-primary mx-1">
            <i class="fa fa-list"></i> Ver lista
          </router-link>
          <router-link to="/agendamentos/add" class="btn btn-primary">
            <i class="fas fa-plus"></i>
            Novo Agendamento
          </router-link>
        </div>
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-12" v-if="loadedFullcalendar">
              <FullCalendar :options="calendarOptions" />
            </div>
          </div>
          <div
            class="modal fade"
            id="calendar"
            tabindex="-1"
            role="dialog"
            aria-labelledby="calendar"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content">
                <div class="modal-header bg-primary">
                  <h5 class="modal-title" style="color: #ffffff !important">
                    {{ modal.nome }}
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body row">
                  <div class="col-md-12 mb-1">
                    <b>{{ modal.descricao }}</b>
                  </div>
                  <div class="col-md-12">
                    <label>Deseja alterar o status?</label>
                    <select v-model="modal.status" class="form-control">
                      <option :value="0">Agendamento Cancelado</option>
                      <option :value="1">Agendado</option>
                      <option :value="2">{{ configuracao.tipo_pessoa }} Ausente</option>
                      <option :value="3">{{ configuracao.tipo_pessoa }} Presente</option>
                      <option :value="4">{{ configuracao.tipo_pessoa }} Atendida</option>
                    </select>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Fechar
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    @click="openAgendamento(modal.id)"
                  >
                    Detalhes
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    data-dismiss="modal"
                    @click="save"
                  >
                    Alterar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            :style="'width: ' + loader + '%;'"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <b style="font-size: 14pt">Carregando</b><br />
        <b style="font-size: 16pt; line-height: 12pt">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import ptLocale from "@fullcalendar/core/locales/pt-br";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";

export default {
  name: "imageUpload",
  data() {
    return {
      configuracao: [],
      actualDate: moment().format("LL"),
      actualMonth: moment().format("MMMM [de] YYYY"),
      calendarOptions: {
        locale: ptLocale,
        eventTimeFormat: {
          hour: "numeric",
          minute: "2-digit",
          meridiem: false,
        },
        slotDuration: '00:05:00',
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        },
        initialView: "timeGridWeek",
        height: "650px",
        width: "500px",
        editable: false,
        selectable: true,
        selectMirror: false,
        dayMaxEvents: false,
        weekends: true,
        allDaySlot: false,
        eventClick: this.enventClick,
        events: [],
      },
      loader: 0,
      loadedFullcalendar: false,
      agendamentos: {},
      modal: {
        status: "",
      },
    };
  },
  computed: {},
  methods: {
    save: function () {
      const self = this;
      const api = self.$store.state.api + `agendamentos/${self.modal.id}`;

      self.modal._method = "PUT";

      axios
        .post(api, self.modal)
        .then(() => {
          self.$message(
            "Sucesso",
            `Informações guardadas com sucesso`,
            "success"
          );

          self.getAgenda();
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAgendamentos: function () {
      const self = this;
      const api = self.$store.state.api + "agenda-restrita";

      axios
        .get(api)
        .then((response) => {
          self.calendarOptions.events = response.data;
          self.setLoader(50);

          setTimeout(() => {
            self.loadedFullcalendar = true;
          }, 1000);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAgenda: function () {
      const self = this;
      const api = self.$store.state.api + "agenda-restrita";

      axios
        .get(api)
        .then((response) => {
          self.calendarOptions.events = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getAgendaCompleta: function () {
      const self = this;
      const api = self.$store.state.api + "agenda-completa";

      self.calendarOptions.events = [];
      self.$loading(true);

      axios
        .get(api)
        .then((response) => {
          self.calendarOptions.events = response.data;
          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    openAgendamento: function(id){
      let routeData = this.$router.resolve("view/" + id);
      window.open(routeData.href, '_blank');
    },
    enventClick(clickInfo) {
      const self = this;
      const api = self.$store.state.api + `agendamentos/${clickInfo.event.id}`;

      self.modal = [];

      axios
        .get(api)
        .then((response) => {
          self.modal = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
      $("#calendar").modal("show");
    },
    getConfiguracao: function () {
      const self = this;
      const api = self.$store.state.api + "configuracoes/";

      axios
        .get(api)
        .then((response) => {
          self.configuracao = response.data.data[0];
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    setLoader: function (qtd) {
      const self = this;

      let load = 0;
      let interval = setInterval(function () {
        self.loader++;
        load++;

        if (load == qtd) {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;

    self.setLoader(50);
    self.getConfiguracao();
    self.getAgendamentos();
  },
  components: {
    BaseCrud,
    vSelect,
    FullCalendar,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>