<template>
  <div>
    <div class="main-content card p-1" v-if="loader === 100">
      <div class="row">
        <div class="col-md-12">
          <h3>{{ manual.titulo }}</h3>
        </div>
        <div class="col-md-12">
          <div class="my-1" v-html="manual.descricao"></div>
        </div>
        <div class="col-md-12 d-flex justify-content-center my-2" v-if="manual.video">
          <video :src="manual.videoaula" class="w-75 bg-dark" style="border: 1px solid #888; border-radius: 8px;" type="video/mp4" controls></video>
        </div>
        <div class="col-md-12 d-flex justify-content-center mt-2" v-if="manual.arquivo">
          <button class="btn btn-outline-primary px-3" @click="acessarPdf()">Acessar arquivo em PDF</button>
        </div>
        <div class="col-md-12 text-right">
          <hr />
          <router-link to="/manuais" class="btn btn-back">
            <i class="fa fa-arrow-left"></i> Voltar
          </router-link>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            :style="'width: ' + loader + '%;'"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <b style="font-size: 14pt">Carregando</b><br />
        <b style="font-size: 16pt; line-height: 12pt">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      loader: 0,
      manual: {},
    };
  },
  computed: {},
  methods: {
    getManual: function (id) {
      const self = this;
      const api = self.$store.state.api + "manuais/" + id;

      axios
        .get(api)
        .then((response) => {
          self.manual = response.data.data[0];

          self.setLoader(50);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    acessarPdf: function(){
      const self = this;
      
      window.open(self.manual.pdf, "_blank");
    },
    setLoader: function (qtd) {
      const self = this;

      let load = 0;
      let interval = setInterval(function () {
        self.loader++;
        load++;

        if (load == qtd) {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    if (id) {
      self.setLoader(50);

      self.getManual(id);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>