import Crud from '../views/Totem/Crud'
import Entity from '../views/Totem/Add'
import View from '../views/Totem/View'

let routes = {
  path: '/totens',
  component: resolve => require(['../layouts/full/LayoutFull'], resolve),
  children: [
    {
      path: '/totens/add',
      name: 'NewTotem',
      component: Entity,
      meta: {
        pageTitle: 'Criação de Totem',
        breadcrumb: [
          {
            text: 'Totens',
            active: false,
            to: '/totens',
          },
          {
            text: 'Criar',
            active: true,
          }
        ],
      },
    },
    {
      path: '/totens/edit/:id?',
      name: 'EditTotem',
      component: Entity,
      meta: {
        pageTitle: 'Edição de Totem',
        breadcrumb: [
          {
            text: 'Totens',
            active: false,
            to: '/totens',
          },
          {
            text: 'Editar',
            active: true,
          }
        ],
      },
    },
    {
      path: '/totens/View/:id?',
      name: 'ViewTotem',
      component: View,
      meta: {
        pageTitle: 'Visualizar Totem',
        breadcrumb: [
          {
            text: 'Totens',
            active: false,
            to: '/totens',
          },
          {
            text: 'Visualizar',
            active: true,
          }
        ],
      },
    },
    {
      path: '/totens',
      name: 'list-totens',
      component: Crud,
      meta: {
        pageTitle: 'Lista de Totens',
        breadcrumb: [
          {
            text: 'Totens',
            active: true,
          },
        ],
      },
    },
  ]
};

export default routes