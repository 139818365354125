<template>
  <div>
    <div class="main-content card p-1" v-if="loader === 100">
      <div class="row justify-content-center">
        <div class="col-12 d-flex align-itens-center justify-content-center">
          <flow-chart :nodes="nodes"
            :connections="connections"
            style="width: 1000px;"
            ref="chart"
            @editnode="abreCard"
            @nodesdragged="loginfo"
          />
        </div>
      </div>
      <div class="modal fade" id="modalBotao" tabindex="-1" role="dialog" aria-labelledby="modalBotaoLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header" :style="'background:' + botao.cor + ';'">
              <h5 class="modal-title" id="modalBotaoLabel" :style="'color: ' + botao.cor_texto + ';'">{{ botao.nome }} ({{ botao.prefixo_senha }})</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <h5 v-if="botao.simbolo_id">Icone:</h5>
              <img :src="botao.simbolo.photo" v-if="botao.simbolo_id" style="border: 2px solid #444; border-radius: 8px; max-height: 100px;">
              <span v-if="botao.simbolo_id"><br><br></span>
              <h5>Grupo prioritário:</h5>
              <span>{{ botao.classificacao ? botao.classificacao.nome : "" }}</span><br><br>
              <h5>Setor:</h5>
              <span>{{ botao.setor ? botao.setor.nome : "" }}</span><br><br>
              <h5>Mensagem:</h5>
              <span>{{ botao.mensagem_senha ? botao.mensagem_senha : "" }}</span><br><br>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            :style="'width: ' + loader + '%;'"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <b style="font-size: 14pt">Carregando</b><br />
        <b style="font-size: 16pt; line-height: 12pt">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";
import FlowChart from 'flowchart-vue';

export default {
  name: "imageUpload",
  data() {
    return {
      loader: 0,
      nodes: [
          {id: 9999, x: 0, y: 0,   name: 'Paciente',      type: 'start'},
          {id: 9998, x: 800, y: 520, name: 'Senha emitida', type: 'end'  },
        ],
        connections: [],
        botao: [],
    };
  },
  computed: {},
  methods: {
    loginfo: function(nodes){
      const self = this;
      const api = self.$store.state.api + "update-coordenada/";

      self.$loading(true);

      let botao = {
        id: nodes[0].id,
        x: nodes[0].x,
        y: nodes[0].y,
      };

      axios
        .post(api, botao)
        .then(() => {

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    getBotoes: function () {
      const self = this;
      const api = self.$store.state.api + "get-fluxo" ;

      axios
        .get(api)
        .then((response) => {
          response.data.nodes.forEach((botao) => {
            self.nodes.push(botao);
          });

          response.data.connections.forEach((botao) => {
            self.connections.push(botao);
          });

          self.setLoader(50);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    abreCard: function(event){
      const self = this;

      if(event.id < 9997)
      {
        self.$loading(true);
        self.getBotao(event.id);
      }
    },
    getBotao: function(id){
      const self = this;
      const api = self.$store.state.api + `botoes/${id}?with[]=simbolo&with[]=setor&with[]=classificacao` ;

      axios
        .get(api)
        .then((response) => {
          self.botao = response.data.data[0];

          $("#modalBotao").modal('toggle');

          self.$loading(false);
        })
        .catch((error) => {
          self.$loading(false);
          self.$message(null, error.response.data, "error");
        });
    },
    setLoader: function (qtd) {
      const self = this;

      let load = 0;
      let interval = setInterval(function () {
        self.loader++;
        load++;

        if (load == qtd) {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;

    self.setLoader(50);
    self.getBotoes();
  },
  components: {
    BaseCrud,
    vSelect,
    FlowChart,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
.flowchart text {
  fill: black;
  font-size: 16px;
  font-family: Arial, sans-serif;
  text-anchor: middle;
}
</style>