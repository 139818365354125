<style>
.local {
  border-bottom: 1px solid #000000;
}
</style>
<template>
  <div>
    <div class="main-content card p-1" v-if="loader === 100">
      <div class="row">
        <div class="col-md-8">
          <label>Nome</label>
          <input type="text" class="form-control" v-model="painel.nome" disabled />
        </div>
        <div class="col-md-4">
          <label>Vídeo</label>
          <v-select
            :options="videos"
            :reduce="(videos) => videos.id"
            :label="'titulo'"
            v-model="painel.video"
            class="vselect"
            disabled
          ></v-select>
        </div>
        <div class="col-md-3">
          <label>Cor do fundo</label>
          <input
            type="color"
            class="form-control"
            v-model="painel.background"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label>Cor dos cards</label>
          <input type="color" class="form-control" v-model="painel.cor_card" disabled/>
        </div>
        <div class="col-md-3">
          <label>Cor primaria</label>
          <input
            type="color"
            class="form-control"
            v-model="painel.cor_primaria"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label>Cor secundaria</label>
          <input
            type="color"
            class="form-control"
            v-model="painel.cor_secundaria"
            disabled
          />
        </div>
        <div class="col-md-3">
          <br />
          <label
            class="btn btn-primary w-100"
            v-bind:class="{ disabled: !painel.relogio }"
            style="cursor: pointer"
          >
            <input type="checkbox" v-model="painel.relogio" class="d-none" disabled />
            Relógio
          </label>
        </div>
        <div class="col-md-3">
          <br />
          <label
            class="btn btn-primary w-100"
            v-bind:class="{ disabled: !painel.previsao }"
            style="cursor: pointer"
          >
            <input type="checkbox" v-model="painel.previsao" class="d-none" disabled />
            Previsão do tempo
          </label>
        </div>
        <div :class="painel.previsao ? 'col-md-3' : 'd-none'">
          <label>Código do IBGE </label>
          <input type="text" class="form-control" v-model="painel.ibge" disabled />
        </div>
        <div :class="painel.previsao ? 'col-md-3' : 'col-md-6'">
          Logo
            <input type="file" class="form-control" id="logo" @input="fileAdd" disabled/>
        </div>
        <div class="col-md-3">
          <label>Arredondamento</label>
          <label class="ml-5">{{ painel.arredondamento }}px</label>
          <input
            type="range"
            v-model="painel.arredondamento"
            class="form-control"
            step="1"
            min="0"
            max="50"
            disabled
          />
        </div>
        <div class="col-md-3">
          <label>Alerta</label>
          <select class="text-center form-control" v-model="painel.alerta" disabled>
            <option :value="'alerta_padrao.mp4'">Alerta padrão</option>
            <option :value="'S'">Chamada por voz</option>
          </select>
        </div>
        <div class="col-md-3">
          <br />
          <label
            class="btn btn-primary w-100"
            v-bind:class="{ disabled: !painel.mostra_pessoa }"
            style="cursor: pointer"
          >
            <input
              type="checkbox"
              v-model="painel.mostra_pessoa"
              class="d-none"
              disabled
            />
            Nome da {{ configuracao.tipo_pessoa }}
          </label>
        </div>
        <div class="col-md-12">
          <label>
            Mensagem
            <span
              :style="
                painel.mensagem.length > 140
                  ? 'color: red; font-weight: 500;'
                  : ''
              "
            >
              ({{ (painel.mensagem.length - 150) * -1 }})
            </span>
          </label>
          <input type="text" class="form-control" v-model="painel.mensagem" maxlength="150" disabled/>
        </div>
        <div class="col-md-12 mt-1 row">
          <div class="col-md-12">
            <label>Setores</label>
          </div>
          <div
            :class="'mt-1 col-md-' + (setores.length < 5 ? '4' : '2')"
            v-for="setor in setores"
            :key="setor.id"
          >
            <button
              class="btn btn-primary w-100"
              :class="setor.active ? 'opacity-100' : 'opacity-50'"
            >
              {{ setor.nome }}
            </button>
          </div>
        </div>
        <div class="col-md-12">
          <hr />
        </div>
        <div
          class="col-md-12 mt-1 mb-1 p-1"
          :style="
            'font-family: helvetica; height: 75vh; background: radial-gradient(' +
            painel.background +
            ', #000000); display: flex;flex-wrap: wrap;'
          "
        >
          <div
            class="d-flex justify-content-center align-items-center"
            :style="
              'width: 69%; height: 84%; background:' +
              painel.cor_card +
              '; border-radius:' +
              painel.arredondamento +
              'px; margin-right: 1%; flex-direction: column;'
            "
            v-if="!painel.video"
          >
            <span
              :style="
                'color:' +
                painel.cor_primaria +
                '; font-size: 6vh;  line-height: 6vh;'
              "
              >Senha</span
            ><br />
            <span
              :style="
                'color:' +
                painel.cor_secundaria +
                '; font-size: 10vh; line-height: 7vh;'
              "
              >A<span :style="'color:' + painel.cor_primaria + ';'"
                >0005</span
              ></span
            ><br />
            <span
              :style="
                'color:' +
                painel.cor_primaria +
                '; font-size: 6vh;  line-height: 6vh;'
              "
              >Local</span
            ><br />
            <span
              :style="
                'color:' +
                painel.cor_primaria +
                '; font-size: 9vh;  line-height: 7vh;'
              "
              >Guichê 01</span
            ><br />
            <span
              :style="
                'color:' +
                painel.cor_primaria +
                '; font-size: 9vh;  line-height: 7vh;'
              "
              v-show="painel.mostra_pessoa"
              >Antônio Carlos Jobim</span
            ><br />
          </div>
          <div
            class="d-flex justify-content-center align-items-center"
            :style="
              'width: 69%; height: 84%; background: #000000; border-radius:' +
              painel.arredondamento +
              'px; margin-right: 1%; flex-direction: column;'
            "
            v-else
          ></div>
          <div
            class="d-flex text-center"
            :style="
              (painel.mostra_pessoa
                ? 'font-size: 2.5vh; '
                : 'font-size: 3.7vh;') +
              'width: 30%; height: 84%; color: ' +
              painel.cor_primaria +
              '; background:' +
              painel.cor_card +
              '; border-radius:' +
              painel.arredondamento +
              'px; flex-direction: column;'
            "
          >
            <span class="local">Últimas Chamadas</span>
            <span :style="'color:' + painel.cor_secundaria + ';'">
              A<span :style="'color:' + painel.cor_primaria">0005</span>
            </span>
            <span v-show="painel.mostra_pessoa">Antônio Carlos Jobim</span>
            <span class="local">Guichê 01</span>
            <span :style="'color:' + painel.cor_secundaria + ';'">
              A<span :style="'color:' + painel.cor_primaria">0004</span>
            </span>
            <span v-show="painel.mostra_pessoa">Heitor Vila-Lobos</span>
            <span class="local">Guichê 01</span>
            <span :style="'color:' + painel.cor_secundaria + ';'">
              A<span :style="'color:' + painel.cor_primaria">0003</span>
            </span>
            <span v-show="painel.mostra_pessoa">Mozart Camargo Guarnieri</span>
            <span class="local">Guichê 01</span>
            <span :style="'color:' + painel.cor_secundaria + ';'">
              A<span :style="'color:' + painel.cor_primaria">0002</span>
            </span>
            <span v-show="painel.mostra_pessoa">Adoniran Barbosa</span>
            <span class="local">Guichê 01</span>
            <span :style="'color:' + painel.cor_secundaria + ';'">
              A<span :style="'color:' + painel.cor_primaria">0001</span>
            </span>
            <span v-show="painel.mostra_pessoa">José Ursino da Silva</span>
            <span>Guichê 01</span>
          </div>
          <div
            :class="
              painel.relogio
                ? 'd-flex align-items-center justify-content-center'
                : 'd-none'
            "
            :style="
              'border-radius:' +
              painel.arredondamento +
              'px; width: 10%; height: 14%; background: ' +
              painel.cor_card +
              '; margin-top: 1%; flex-direction: column;'
            "
          >
            <span
              :style="'color:' + painel.cor_primaria + '; font-size: 2.5vh;'"
              >01/01/2000</span
            >
            <span
              :style="'color:' + painel.cor_primaria + '; font-size: 2.5vh;'"
              >00:00:00</span
            >
          </div>
          <div
            :class="
              painel.previsao
                ? 'd-flex align-items-center justify-content-center'
                : 'd-none'
            "
            :style="
              'border-radius:' +
              painel.arredondamento +
              'px; width: 19%; height: 14%; background: ' +
              painel.cor_card +
              '; margin-top: 1%; margin-left: ' +
              (painel.relogio ? '1' : '0') +
              '%;'
            "
          >
            <span :style="'color:' + painel.cor_primaria + '; font-size: 3vh;'"
              >Previsão do tempo</span
            >
          </div>
          <div
            class="d-flex align-items-center justify-content-center"
            :style="
              'border-radius:' +
              painel.arredondamento +
              'px; width: ' +
              (100 - ((painel.relogio ? 11 : 0) + (painel.previsao ? 20 : 0) + (hasLogo ? 31 : 0))) +
              '%; height: 14%; background: ' +
              painel.cor_card +
              '; margin-top: 1%; margin-left: ' +
              (painel.relogio + painel.previsao ? '1' : '0') +
              '%;'
            "
          >
            <span
              :style="'color:' + painel.cor_primaria + '; font-size: 6vh;'"
              v-if="painel.mensagem && painel.mensagem.length < 50"
            >
              {{ painel.mensagem }}
            </span>
            <span
              :style="'color:' + painel.cor_primaria + '; font-size: 6vh;'"
              v-else
            >
              (Texto)
            </span>
          </div>
          <div
          :class="
              hasLogo
                ? 'd-flex align-items-center justify-content-center'
                : 'd-none'
            "
            :style="
              'border-radius:' + painel.arredondamento +
              'px; width: 30%; height: 14%; background: ' +
              painel.cor_card +
              '; margin-top: 1%; margin-left: 1%;'
            "
            >
            <img :src="imageData" style="max-height: 90%; max-width: 98%;">
          </div>
        </div>
        <div class="col-md-12 text-right">
          <hr />
          <router-link to="/paineis" class="btn btn-back">
            <i class="fa fa-arrow-left"></i> Voltar
          </router-link>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            :style="'width: ' + loader + '%;'"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <b style="font-size: 14pt">Carregando</b><br />
        <b style="font-size: 16pt; line-height: 12pt">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      imageData: null,
      hasLogo: false,
      configuracao: [],
      nome_arquivo: "Sem Arquivo",
      loader: 0,
      painel: {
        background:     "#15488a",
        cor_card:       "#ffffff",
        cor_primaria:   "#15488a",
        cor_secundaria: "#ff0000",
        mostra_pessoa:  0,
        arredondamento: 0,
        mensagem:       "",
        ativo:          "1",
      },
      setores: [],
      painelsetors: [],
    };
  },
  computed: {},
  methods: {
    getPaineis: function (id) {
      const self = this;
      const api = self.$store.state.api + "paineis/" + id;

      axios
        .get(api)
        .then((response) => {
          self.painel = response.data.data[0];

          if(self.painel.logo)
          {
            self.setLogo();
          }

          self.setLoader(30);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getSetores: function () {
      const self = this;
      const api = self.$store.state.api + "setores?orderByAsc=nome&ativo=1&paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.setores = response.data.data;

          for (let i = 0; i < self.setores.length; i++) {
            self.setores[i].active = false;
          }
          self.setLoader(25);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getPainelSetores: function (id) {
      const self = this;
      const api = self.$store.state.api + `painel_setores/${id}?paginated=false`;

      axios
        .get(api)
        .then((response) => {
          self.painelsetors = response.data.data;

          self.painelsetors.forEach((painelsetor) => {
            let setor = self.setores.find(
              (setor) => setor.id === painelsetor.setor_id
            );
            if (setor) {
              setor.active = true;
            }
          });
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getVideos: function () {
      const self = this;
      const api = self.$store.state.api + "videos?paginated=false";

      axios
        .get(api)
        .then((response) => {
          self.videos = response.data.data;

          self.setLoader(10);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    getConfiguracao: function () {
      const self = this;
      const api = self.$store.state.api + "configuracoes/";

      axios
        .get(api)
        .then((response) => {
          self.configuracao = response.data.data[0];
          self.setLoader(5);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    fileAdd: function(event) {
      let logo = document.getElementById("logo");

      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(file);
        this.hasLogo = true;
      } else {
        this.imageData = null;
        this.hasLogo = false;
        self.$message(null, 'Por favor, selecione um arquivo de imagem válido.', "error");
      }
    },
    setLogo: function() {
      const self = this;

      self.hasLogo = true;
      self.imageData = self.painel.logo_f;
    },
    setLoader: function (qtd) {
      const self = this;

      let load = 0;
      let interval = setInterval(function () {
        self.loader++;
        load++;

        if (load == qtd) {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    setTimeout(function () {
      self.setLoader(30);
    }, 300);

    self.getConfiguracao();
    self.getSetores();
    self.getVideos();

    if (id) {
      self.getPaineis(id);
      self.getPainelSetores(id);
    } else {
      setTimeout(function () {
        self.setLoader(30);
      }, 300);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}

.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}

.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important;
}

.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: 0.5;
}
</style>