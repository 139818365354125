import Crud from '../views/Motivo/Crud'
import Entity from '../views/Motivo/Add'
import View from '../views/Motivo/View'

let routes = {
    path: '/motivos',
    component: resolve => require(['../layouts/full/LayoutFull'], resolve),
    children: [
        {
            path: '/motivo_cancelamentos/add',
            name: 'NewMotivo',
            component: Entity,
            meta: {
                pageTitle: 'Criação de Motivo de Cancelamento',
                breadcrumb: [
                    {
                        text: 'Motivos de Cancelamento',
                        active: false,
                        to: '/motivo_cancelamentos',
                    },
                    {
                        text: 'Criar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/motivo_cancelamentos/edit/:id?',
            name: 'EditMotivo',
            component: Entity,
            meta: {
                pageTitle: 'Edição de Motivo de Cancelamento',
                breadcrumb: [
                    {
                        text: 'Motivos de Cancelamento',
                        active: false,
                        to: '/motivo_cancelamentos',
                    },
                    {
                        text: 'Editar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/motivo_cancelamentos/view/:id?',
            name: 'ViewMotivo',
            component: View,
            meta: {
                pageTitle: 'Visualizar Motivo de Cancelamento',
                breadcrumb: [
                    {
                        text: 'Motivos de Cancelamento',
                        active: false,
                        to: '/motivo_cancelamentos',
                    },
                    {
                        text: 'Visualizar',
                        active: true,
                    }
                ],
            },
        },
        {
            path: '/motivo_cancelamentos',
            name: 'list-motivos',
            component: Crud,
            meta: {
                pageTitle: 'Lista de Motivos de Cancelamento',
                breadcrumb: [
                    {
                        text: 'Motivos de Cancelamento',
                        active: true,
                    },
                ],
            },
        },
    ]
};


export default routes
