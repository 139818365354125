<template>
  <div>
    <div class="main-content card p-1" v-if="loader === 100">
      <div class="row">
        <div class="col-md-12">
          <label>ID da unidade</label>
          <input type="text" class="form-control" v-model="unidade.id" disabled/>
        </div>
        <div class="col-md-12">
          <label>CNES</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="unidade.cnes" disabled />
          </div>
        </div>
        <div class="col-md-12">
          <label>Nome</label>
          <input type="text" class="form-control" v-model="unidade.nome" id="nome" disabled/>
        </div>
        <div class="col-md-12">
          <label>Nome fantasia</label>
          <input type="text" class="form-control" v-model="unidade.nome_fantasia" disabled />
        </div>
        <div class="col-md-12">
          <label>Razão social</label>
          <input type="text" class="form-control" v-model="unidade.razao_social" disabled />
        </div>
        <div class="col-md-6">
          <label>CNPJ da entidade</label>
          <input type="text" class="form-control" v-model="unidade.cnpj_entidade" v-mask="'99.999.999/9999.99'" disabled />
        </div>
        <div class="col-md-6">
          <label>CNPJ da unidade</label>
          <input type="text" class="form-control" v-model="unidade.cnpj_unidade" v-mask="'99.999.999/9999.99'" disabled />
        </div>
        <div class="col-md-6">
          <label>Tipo de unidade</label>
          <v-select v-model="unidade.tipo_unidade_id" :options="tipo_unidade_id" :label="'nome'"
            :reduce="(tipo_unidade_id) => tipo_unidade_id.id" class="vselect" disabled
          />
        </div>
        <div class="col-md-6">
          <label>Tipo da gestão</label>
          <v-select v-model="unidade.tipo_gestao" :options="tipo_gestao" :label="'nome'"
            :reduce="(tipo_gestao) => tipo_gestao.id" class="vselect" disabled
          />
        </div>
        <div class="col-md-3">
          <label>CEP</label>
          <div class="input-group">
            <input type="text" class="form-control" v-model="unidade.cep" v-mask="'99999-999'" disabled />
            <div class="input-group-append">
              <button class="btn btn-primary" type="button" @click="buscar">
                <i class="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <label>Logradouro</label>
          <input type="text" class="form-control" v-model="unidade.logradouro" disabled />
        </div>
        <div class="col-md-2">
          <label>Número</label>
          <input type="text" class="form-control" v-model="unidade.numero" disabled />
        </div>
        <div class="col-md-3">
          <label>Complemento</label>
          <input type="text" class="form-control" v-model="unidade.complemento" disabled />
        </div>
        <div class="col-md-3">
          <label>Bairro</label>
          <input type="text" class="form-control" v-model="unidade.bairro" disabled />
        </div>
        <div class="col-md-3">
          <label>Município</label>
          <input type="text" class="form-control" v-model="unidade.municipio" disabled />
        </div>
        <div class="col-md-3">
          <label>Estado</label>
          <input type="text" class="form-control" v-model="unidade.uf" disabled />
        </div>
        <div class="col-md-12">
          <label>Telefone</label>
          <input type="text" class="form-control" v-model="unidade.telefone" disabled />
        </div>
        <div class="col-md-12">
          <label>Email</label>
          <input type="text" class="form-control" v-model="unidade.email" disabled />
        </div>
        <div class="col-md-12 text-right">
          <hr />
          <router-link to="/unidades" class="btn btn-back">
            <i class="fa fa-arrow-left"></i> Voltar
          </router-link>
        </div>
      </div>
    </div>
    <div class="row justify-content-center mt-3" v-else>
      <div class="col-10 text-center">
        <div class="progress">
          <div
            class="progress-bar bg-primary"
            role="progressbar"
            :style="'width: ' + loader + '%;'"
            aria-valuemin="0"
            aria-valuemax="100"
          ></div>
        </div>
        <b style="font-size: 14pt">Carregando</b><br />
        <b style="font-size: 16pt; line-height: 12pt">{{ loader }}%</b>
      </div>
    </div>
  </div>
</template>
<script>
import BaseCrud from "../../layouts/Base/BaseCrud";
import vSelect from "vue-select";

export default {
  name: "imageUpload",
  data() {
    return {
      loader: 0,
      unidade: [],
      unidade_id_original: 0,
      tipo_unidade_id: [
        {"id": 1,  "nome": "POSTO DE SAUDE" },
        {"id": 2,  "nome": "CENTRO DE SAUDE/UNIDADE BASICA" },
        {"id": 4,  "nome": "POLICLINICA" },
        {"id": 5,  "nome": "HOSPITAL GERAL" },
        {"id": 7,  "nome": "HOSPITAL ESPECIALIZADO" },
        {"id": 15, "nome": "UNIDADE MISTA" },
        {"id": 20, "nome": "PRONTO SOCORRO GERAL" },
        {"id": 21, "nome": "PRONTO SOCORRO ESPECIALIZADO" },
        {"id": 22, "nome": "CONSULTORIO ISOLADO" },
        {"id": 32, "nome": "UNIDADE MOVEL FLUVIAL" },
        {"id": 36, "nome": "CLINICA/CENTRO DE ESPECIALIDADE" },
        {"id": 39, "nome": "UNIDADE DE APOIO DIAGNOSE E TERAPIA (SADT ISOLADO)" },
        {"id": 40, "nome": "UNIDADE MOVEL TERRESTRE" },
        {"id": 42, "nome": "UNIDADE MOVEL DE NIVEL PRE-HOSPITALAR NA AREA DE URGENCIA" },
        {"id": 43, "nome": "FARMACIA" },
        {"id": 50, "nome": "UNIDADE DE VIGILANCIA EM SAUDE" },
        {"id": 60, "nome": "COOPERATIVA OU EMPRESA DE CESSAO DE TRABALHADORES NA SAUDE" },
        {"id": 61, "nome": "CENTRO DE PARTO NORMAL - ISOLADO" },
        {"id": 62, "nome": "HOSPITAL/DIA - ISOLADO" },
        {"id": 64, "nome": "CENTRAL DE REGULACAO DE SERVICOS DE SAUDE" },
        {"id": 67, "nome": "LABORATORIO CENTRAL DE SAUDE PUBLICA LACEN" },
        {"id": 68, "nome": "CENTRAL DE GESTAO EM SAUDE" },
        {"id": 69, "nome": "CENTRO DE ATENCAO HEMOTERAPIA E OU HEMATOLOGICA" },
        {"id": 70, "nome": "CENTRO DE ATENCAO PSICOSSOCIAL" },
        {"id": 71, "nome": "CENTRO DE APOIO A SAUDE DA FAMILIA" },
        {"id": 72, "nome": "UNIDADE DE ATENCAO A SAUDE INDIGENA" },
        {"id": 73, "nome": "PRONTO ATENDIMENTO" },
        {"id": 74, "nome": "POLO ACADEMIA DA SAUDE" },
        {"id": 75, "nome": "TELESSAUDE" },
        {"id": 76, "nome": "CENTRAL DE REGULACAO MEDICA DAS URGENCIAS" },
        {"id": 77, "nome": "SERVICO DE ATENCAO DOMICILIAR ISOLADO(HOME CARE)" },
        {"id": 78, "nome": "UNIDADE DE ATENCAO EM REGIME RESIDENCIAL" },
        {"id": 79, "nome": "OFICINA ORTOPEDICA" },
        {"id": 80, "nome": "LABORATORIO DE SAUDE PUBLICA" },
        {"id": 81, "nome": "CENTRAL DE REGULACAO DO ACESSO" },
        {"id": 82, "nome": "CENTRAL DE NOTIFICACAO,CAPTACAO E DISTRIB DE ORGAOS ESTADUAL" },
        {"id": 83, "nome": "POLO DE PREVENCAO DE DOENCAS E AGRAVOS E PROMOCAO DA SAUDE" },
        {"id": 84, "nome": "CENTRAL DE ABASTECIMENTO" },
        {"id": 85, "nome": "CENTRO DE IMUNIZACAO" }
      ],
      tipo_gestao: [
        {
          id: "E",
          nome: "Estadual",
        },
        {
          id: "M",
          nome: "Municipal"
        },
      ],
    };
  },
  computed: {},
  methods: {
    getunidade: function (id) {
      const self = this;
      const api = self.$store.state.api + "unidades/" + id;

      axios
        .get(api)
        .then((response) => {
          self.unidade = response.data.data[0];

          self.unidade.unidade_id_original = self.unidade.id

          self.setLoader(50);
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
    buscar: function () {
      const self = this;

      self.$loading(true);

      self.unidade.logradouro = '';
      self.unidade.bairro     = '';
      self.unidade.municipio  = '';
      self.unidade.uf         = '';
      self.unidade.push();

      if (/^[0-9]{5}-[0-9]{3}$/.test(self.unidade.cep)) {
        $.getJSON(
          "https://viacep.com.br/ws/" + self.unidade.cep + "/json/",
          function (endereco) {

            console.log(endereco)
            self.$loading(false);

            if (endereco.erro) {
              self.endereco = {};
              $("#numero").focus();
              self.$message(null, "CEP não localizado", "error");
              return;
            }

            self.unidade.logradouro = endereco.logradouro;
            self.unidade.bairro     = endereco.bairro;
            self.unidade.municipio  = endereco.localidade;
            self.unidade.uf         = endereco.uf;
            self.unidade.push();
          }
        );
      }
    },
    setLoader: function (qtd) {
      const self = this;

      let load = 0;
      let interval = setInterval(function () {
        self.loader++;
        load++;

        if (load == qtd) {
          clearInterval(interval);
        }
      }, 15);
    },
  },
  mounted: function () {
    const self = this;
    let id = self.$route.params.id;

    self.setLoader(50);

    if (id) {
      self.getunidade(id);
    } else {
      self.setLoader(50);
    }
  },
  components: {
    BaseCrud,
    vSelect,
  },
};
</script>
<style scoped>
.profile_user {
  text-align: center;
}
.photo {
  display: block;
  margin-left: 5px;
  width: 18%;
  border-radius: 10px;
  max-width: 400px;
  max-height: 400px;
  object-fit: cover;
}
.btn-user:hover,
.btn-user[aria-expanded="true"] {
  background: linear-gradient(to right, #000, #666);
  color: #fff !important ;
}
.photo-title {
  color: #000;
  font-size: 18px;
  display: block;
  margin-left: 5px;
  width: 18%;
}
</style>